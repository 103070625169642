.common_page {
  box-sizing: border-box;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  position: relative;
}

.full_screen_swiper {
  width: 100%;
  height: 100vh;
}

.scroll_img {
  cursor: pointer;
  z-index: 9;
  width: 29px;
  height: 65px;
  position: fixed;
  bottom: 64px;
  left: 50%;
  transform: translateX(-50%);
}

#page_1, #page_2 {
  background-image: url("d20211025_1.412647e8.png");
  background-position: 100% 0;
  background-size: 61.67%;
  overflow: hidden;
}

#page_2 {
  background-image: url("d20211025_3.639d13ba.png");
}

#page_1 .header {
  padding: 22px 12.5% 0;
  display: flex;
}

#page_1 .header a {
  text-align: center;
  color: #333;
  border-radius: 6px;
  width: 100px;
  height: 38px;
  line-height: 38px;
}

#page_1 .header .downCEF {
  color: #fff;
  background-color: #333;
}

#page_1 .header > div {
  flex: 1;
  height: 51px;
  display: flex;
}

#page_1 .logo {
  height: 100%;
  margin-right: 10px;
}

#page_1 > img, #page_2 > img {
  width: 44.64%;
  margin-top: 14.68%;
  margin-left: 34.22%;
  display: block;
}

#page_1 > img {
  margin-top: 11.41%;
}

#page_3 {
  background-image: url("d20211025_5.58a9f027.png");
  background-position: 0 0;
  background-size: auto 100%;
  overflow: hidden;
}

#page_3 > img {
  width: 44.635%;
  margin-top: 13.594%;
  margin-left: 51.927%;
  display: block;
}

#page_4 {
  position: relative;
}

#page_4 .bg_img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.pos_img {
  width: 38.65%;
  margin-top: 11.82%;
  position: absolute;
  top: 0;
  right: 0;
}

.fixed_left {
  color: #000;
  z-index: 10;
  margin-top: 13.958%;
  margin-left: 12.5%;
  position: absolute;
  left: 0;
  transform: unset !important;
  top: 0 !important;
}

.fixed_left .swiper-pagination-bullet {
  opacity: 1;
  background-color: #0000;
  width: auto;
  height: auto;
  display: block;
}

.fixed_left.white {
  color: #fff;
}

.fixed_left p {
  margin-bottom: 50px;
  font-size: 16px;
  line-height: 16px;
}

.fixed_left .title {
  margin-bottom: 30px;
  font-size: 30px;
  font-weight: 500;
  line-height: 30px;
}

.fixed_left .swiper-pagination-bullet-active .title {
  font-size: 50px;
  line-height: 50px;
}

.fix_bottom {
  text-align: center;
  color: #333;
  z-index: 3;
  width: 100%;
  font-size: 12px;
  position: absolute;
  bottom: 30px;
  left: 0;
}

.fix_bottom.white {
  color: #fff;
}

/*# sourceMappingURL=index.72390757.css.map */
