.common_page{
    width: 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;
    height: 100vh;
    position: relative;
}

.full_screen_swiper{
    width: 100%;
    height: 100vh;
}

.scroll_img{
    position: fixed;
    width: 29px;
    height: 65px;
    bottom: 64px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
    z-index: 9;
}

#page_1,#page_2{
    background-image: url("../static.steambig.com/game_release/d20211025_1.png")/*tpa=http://yxgj.rzxyxx.cn/static.steambig.com/game_release/d20211025_1.png*/;
    background-size: 61.67% auto;
    background-position: right top;
    overflow: hidden;
}
#page_2{
    background-image: url("../static.steambig.com/game_release/d20211025_3.png")/*tpa=http://yxgj.rzxyxx.cn/static.steambig.com/game_release/d20211025_3.png*/;
}

#page_1 .header{
    display: flex;
    padding: 22px 12.5% 0;
}
#page_1 .header a{
    width: 100px;
    line-height: 38px;
    height: 38px;
    border-radius: 6px;
    text-align: center;
    color: #333;
}

#page_1 .header .downCEF{
    background-color: #333333;
    color: #fff;
}

#page_1 .header > div{
    display: flex;
    height: 51px;
    flex: 1;
}

#page_1 .logo{
    height: 100%;
    margin-right: 10px;
}
#page_1 >img,#page_2 >img{
    display: block;
    width: 44.64%;
    margin-left: 34.22%;
    margin-top: 14.68%;
}

#page_1 >img{
    margin-top: 11.41%;
}

#page_3{
    background-image: url("../static.steambig.com/game_release/d20211025_5.png")/*tpa=http://yxgj.rzxyxx.cn/static.steambig.com/game_release/d20211025_5.png*/;
    background-size: auto 100%;
    background-position: 0 0;
    overflow: hidden;
}

#page_3 >img{
    margin-top: 13.594%;
    display: block;
    margin-left: 51.927%;
    width: 44.635%;
}

#page_4{
    position: relative;
}


#page_4 .bg_img{
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pos_img{
    position: absolute;
    width: 38.65%;
    right: 0;
    margin-top: 11.82%;
    top: 0;
}


.fixed_left{
    position: absolute;
    margin-left: 12.5%;
    margin-top: 13.958%;
    top: 0 !important;
    left: 0;
    color: #000;
    z-index: 10;
    transform: unset !important;
}
.fixed_left .swiper-pagination-bullet{
    width: auto;
    height: auto;
    background-color: transparent;
    display: block;
    opacity: 1;
}

.fixed_left.white{
    color: #fff;
}

.fixed_left p{
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 50px;
}

.fixed_left .title{
    font-size: 30px;
    font-weight: 500;
    line-height: 30px;
    margin-bottom: 30px;
}

.fixed_left .swiper-pagination-bullet-active .title{
    font-size: 50px;
    line-height: 50px;
}

.fix_bottom{
    position: absolute;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #333333;
    left: 0;
    bottom: 30px;
    z-index: 3;
}
.fix_bottom.white{
    color: #fff;
}